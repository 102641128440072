import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';
import { IconZap } from '@components/icons';
import { mixins, Main, media } from '@styles';

const StyledMainContainer = styled(Main)`
  counter-reset: section;
`;
const StyledHeading = styled.h4`
  text-align: center;
  margin: 0 0 20px;
  font-size: 60px;
  ${media.desktop`font-size: 50px;`};
  ${media.tablet`font-size: 40px;`};
`;
const StyledGrid = styled.div`
  margin-top: 50px;

  .blogs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
`;
const StyledBlogInner = styled.div`
  ${mixins.boxShadow};
  ${mixins.flexBetween};
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  border-radius: var(--border-radius);
  transition: var(--transition);
  background-color: var(--light-navy);

  header,
  a {
    width: 100%;
  }
`;
const StyledBlog = styled.div`
  transition: var(--transition);
  cursor: default;

  &:hover,
  &:focus {
    outline: 0;
    ${StyledBlogInner} {
      transform: translateY(-5px);
    }
  }
`;
const StyledBlogHeader = styled.div`
  ${mixins.flexBetween};
  margin-bottom: 30px;
`;
const StyledFolder = styled.div`
  color: var(--green);
  svg {
    width: 40px;
    height: 40px;
  }
`;
const StyledBlogName = styled.h5`
  margin: 0 0 10px;
  color: var(--lightest-slate);
  font-size: var(--fz-xxl);
`;
const StyledBlogDescription = styled.div`
  color: var(--light-slate);
  font-size: 17px;
`;
const StyledDate = styled.span`
  color: var(--light-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xxs);
  text-transform: uppercase;
`;

const BlogPage = ({ location, data }) => {
  const blogs = data.allMarkdownRemark.edges;

  return (
    <Layout location={location}>
      <Helmet title="Blog" />

      <StyledMainContainer>
        <StyledHeading>Blog</StyledHeading>

        <StyledGrid>
          <div className="blogs">
            {blogs.length > 0 &&
              blogs.map(({ node }, i) => {
                const { frontmatter } = node;
                const { title, description, slug, date } = frontmatter;
                const d = new Date(date);

                return (
                  <StyledBlog key={i} tabIndex="0">
                    <StyledBlogInner>
                      <header>
                        <Link to={slug}>
                          <StyledBlogHeader>
                            <StyledFolder>
                              <IconZap />
                            </StyledFolder>
                          </StyledBlogHeader>
                          <StyledBlogName>{title}</StyledBlogName>
                          <StyledBlogDescription>{description}</StyledBlogDescription>
                        </Link>
                      </header>
                      <footer>
                        <StyledDate>{`${d.toLocaleDateString()}`}</StyledDate>
                      </footer>
                    </StyledBlogInner>
                  </StyledBlog>
                );
              })}
          </div>
        </StyledGrid>
      </StyledMainContainer>
    </Layout >
  );
};

BlogPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default BlogPage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" }, frontmatter: { draft: { ne: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            slug
            date
            draft
          }
          html
        }
      }
    }
  }
`;
